import React from "react";
import Obfuscate from "react-obfuscate";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "../style/normalize.css";
import "../style/all.scss";

const ImpressumPage = ({ data }, location) => {
  return (
    <Layout>
      <SEO title="Impressum" keywords={[`michael gwerder`, `impressum`]} />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Impressum</h2>
          <p>
            Michael Gwerder
            <br />
            <Obfuscate
              email="kontakt@michaelgwerder.ch"
              headers={{
                subject: "Kontakt über Website"
              }}
            />
          </p>
        </div>
      </article>
    </Layout>
  );
};

export default ImpressumPage;
